


























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ClientConfigurationsModal } from '../../models';
import OverlayLoader from '../shared/OverlayLoader.vue';

@Component({
  components: {
    OverlayLoader,
  },
})
export default class ClientConfigurationsList extends Vue {
  isLoading: boolean = false;

  @Watch('environment')
  onEnvironmentChange(value: string, old: string) {
    this.isLoading = true;
    this.$store.dispatch('loadClientConfigurations', this.environment)
      .then(() => {
        this.isLoading = false;
      });
  }

  get clientConfigurations(): ClientConfigurationsModal[] {
    return this.$store.state.clientConfigurations;
  }

  get environment(): string {
    return this.$store.state.environment;
  }

  getItemIndex(id: string) {
    const index = this.$store.state.clientConfigurations.findIndex(
      (x: ClientConfigurationsModal) => x.id === id,
    );
    return index + 1;
  }

  mounted(): void {
    this.isLoading = true;
    this.$store.dispatch('loadClientConfigurations', this.environment)
      .then(() => {
        this.isLoading = false;
      });
  }
}
