







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ClientConfigurationsList from '@/components/clientConfigurations/ClientConfigurationsList.vue';
import ClientConfigurationsHeader from '@/components/clientConfigurations/ClientConfigurationsHeader.vue';

@Component({
  components: {
    ClientConfigurationsList,
    ClientConfigurationsHeader,
  },
})
export default class ClientConfigurationsView extends Vue {
}
